import './style.css'
import data from './Experience/data.js'
import 'latest-createjs'
import CSSPlugin from './lib/CSSPlugin.js'
import Experience from './Experience/Experience.js'

createjs.CSSPlugin.install()
createjs.Ticker.timingMode = createjs.Ticker.RAF
const c = createjs //Shortcut

const experience = new Experience(document.querySelector('canvas.webgl'))

/**
 * Retrieve Elements
 */
const loader = document.querySelector('.loader')
const webglScreen = document.querySelector('.webgl')
const htmlInterface = document.querySelector('.interface')

const mobileInterface = 
{
    overlay: htmlInterface.querySelector('.mobile-overlay'),
    title: htmlInterface.querySelector('.mobile-overlay').querySelector('h2'),
    text: htmlInterface.querySelector('.mobile-overlay').querySelector('p'),
    projectList: htmlInterface.querySelector('.projects-list'),
    about: htmlInterface.querySelector('.mobile-about'),
    skills: htmlInterface.querySelector('.mobile-skills'),
    contact: htmlInterface.querySelector('.mobile-contact'),
    nav: htmlInterface.querySelector('.mobile-nav'),
    crossIcon: htmlInterface.querySelector('.mobile-overlay').querySelector('.cross-icon'),
    isAnimating: false // Prevent error
}
const desktopInterface = 
{
    overlay: htmlInterface.querySelector('.desktop-overlay'),
    title: htmlInterface.querySelector('.desktop-overlay').querySelector('h2'),
    text: htmlInterface.querySelector('.desktop-overlay').querySelector('p'), 
    projectContainer: htmlInterface.querySelector('.desktop-overlay').querySelector('.project-container'),
    projectCard: htmlInterface.querySelector('.desktop-overlay').querySelector('.card'), 
    bullets: htmlInterface.querySelector('.desktop-overlay').querySelector('.bullets'),
    projectTags: htmlInterface.querySelector('.desktop-overlay').querySelector('.project-tags'),
    about: htmlInterface.querySelector('.about-container'),
    skills: htmlInterface.querySelector('.desktop-skills'),
    contact: htmlInterface.querySelector('.contact-container'),
    cursorExtension: htmlInterface.querySelector('.cursor-extension'),
    cursorExtensionText: htmlInterface.querySelector('.cursor-extension').querySelector('span'),
    cursorEntensionVisible: false,
    isInProjects: false,
    bulletsArr: [],
    projectTagsArr: [],
    maxProjectTags: 6,
    currProject: 0,
    overlayDuration: 700 // Time to close overlay
}
const overlayContent =
{
    projectsTitle: "Projects",
    projectsText: "Hey there, check out my main projects.",
    aboutTitle: "Hey, I'm Fernando.",
    aboutText: "With a diverse academic background, I'm on a mission to craft user-centric experiences, driven by a passion for meaningful interactions and problem solving.",
    contactTitle: "Let's Connect",
    contactText: "Thank you for exploring my work! If you have any questions, comments, or just want to say hi, I'm here and eager to connect."
}

/**
 * Setup
 */
let isMobile
init()

/**
 * Functions
 */
function init()
{
    resize()
    setData()
    setEventListeners()
    setLoader()
}

function setEventListeners()
{
    const switchBtn = document.querySelector('.switch').firstChild
    const projectsBtn = document.querySelector('.projectsBtn')
    const aboutBtn = document.querySelector('.aboutBtn')
    const contactBtn = document.querySelector('.contactBtn')
    const closeOverlayBtns = document.querySelectorAll('.cross-icon')
    const nextProjectBtns = document.querySelectorAll('.next-icon') 
    const linkedinBtns = document.querySelectorAll('.linkedin-icon')
    const resumeBtns = document.querySelectorAll('.resumeBtn')

    window.addEventListener('resize', resize)
    
    webglScreen.addEventListener('mousedown', (evt)=>
    {
        if ("buttons" in evt && experience.world.points.canUpdate) {
            if (evt.buttons == 1) {
                webglScreen.style.cursor = 'grabbing'
            }
        }
    })
    webglScreen.addEventListener('mouseup', ()=>
    {
        if(experience.world.points.canUpdate)
        {
            webglScreen.style.cursor = 'grab'
        }
    })
    
    // Mobile Btns
    projectsBtn.addEventListener('click', projectsMobile)
    aboutBtn.addEventListener('click', aboutMobile)
    contactBtn.addEventListener('click', contactMobile)

    // Misc - Interface
    closeOverlayBtns.forEach((btn)=>
    {
        btn.addEventListener('click', closeOverlay)
    })

    nextProjectBtns.forEach((btn, index)=>
    {
        index == 0
        ? btn.addEventListener('click', function(){updateProject(false)}) 
        : btn.addEventListener('click', function(){updateProject(true)})
    })

    switchBtn.addEventListener('click', ()=>{
        if(switchBtn.checked == true)
        {
            darkMode()
        }
        else
        {
            lightMode()
        }
    })

    desktopInterface.projectCard.addEventListener('click', function()
    {
        openProject(data[desktopInterface.currProject]['url'])
    })

    document.addEventListener('mousemove', (e) => {
        const { clientX, clientY } = e;
        const offsetX = desktopInterface.cursorExtension.clientWidth * 0.5;
        const offsetY = desktopInterface.cursorExtension.clientHeight * 0.7;

        // Update cursor position
        desktopInterface.cursorExtension.style.transform = `translate(${clientX - offsetX}px, ${clientY - offsetY}px)`;
    
        // Check if the mouse is over the card
        const isHovering = isMouseOverElement(e, desktopInterface.projectCard);
        desktopInterface.cursorExtensionText.style.transform = isHovering ? ' scale(1)' : ' scale(0)';
    });

    document.addEventListener('keydown', (e) => {
        if (desktopInterface.isInProjects) {
            switch (e.key) {
                case 'ArrowLeft':
                    // Handle left arrow key press
                    updateProject(false);
                    break;
                case 'ArrowRight':
                    // Handle right arrow key press
                    updateProject(true);
                    break;
                // Add more cases if needed for other keys
            }
        }
    });

    linkedinBtns.forEach((btn)=>
    {
        btn.addEventListener('click', function()
        {
            openProject("https://www.linkedin.com/in/fernandcamps/")
        })
    })
}

function setData()
{
    const skillsArr = 
    [
        'UX', 
        'Research', 
        'Interaction', 
        'UI', 
        'HTML', 
        'CSS', 
        'JS', 
        'ThreeJS', 
        'CreateJS', 
        'Communication', 
        'Collaboration', 
        'Storytelling'
    ]

    // Projects data
    data.forEach((i)=>
    {
        preloadImage(i.imagePath)
        // Mobile
        let project = document.createElement('div')
        project.classList.add('projectCard')
        project.style.backgroundImage = `url(${i.imagePath})`
        project.style.backgroundColor = i.bgColor
        project.addEventListener('click', function()
        {
            openProject(i.url)
        })

        let projectTitle = document.createElement('h3')
        projectTitle.innerHTML = i.name

        let projectDescription = document.createElement('p')
        projectDescription.innerHTML = i.shortDescription

        mobileInterface.projectList.appendChild(project)
        mobileInterface.projectList.appendChild(projectTitle)
        mobileInterface.projectList.appendChild(projectDescription)
    })

    for(let i = 0; i < data.length; i++)
    {
        let bullet = document.createElement('div')
        desktopInterface.bullets.appendChild(bullet)
    }
    for(let i = 0; i < desktopInterface.maxProjectTags; i++)
    {
        let tag = document.createElement('h5')
        tag.classList.add('skill-label')
        desktopInterface.projectTags.appendChild(tag)
    }
    desktopInterface.bulletsArr = Array.from(desktopInterface.bullets.children)
    desktopInterface.projectTagsArr = Array.from(desktopInterface.projectTags.children)

    // Skills about me
    skillsArr.forEach((i)=>
    {
        let skillMobile = document.createElement('h5')
        let skillDesktop = document.createElement('h5')

        skillMobile.innerHTML = skillDesktop.innerHTML = i

        skillMobile.classList.add('skill-label')
        skillDesktop.classList.add('skill-label')

        mobileInterface.skills.appendChild(skillMobile)
        desktopInterface.skills.appendChild(skillDesktop)
    })
}

function setLoader()
{
    const loaderIsland = loader.lastChild

    Array.from(loaderIsland.children).forEach((child)=>{
        const path = child
        const length = path.getTotalLength()
        path.style.strokeDasharray = length
        path.style.strokeDashoffset = length
        path.classList.add('loaderAnim')
    })

    experience.resources.on('ready', ()=>
    {   
        Array.from(loaderIsland.children).forEach((child)=>{
            const path = child
            path.style.animationFillMode = 'forwards'
            path.style.animationDirection = 'normal'
            path.style.animationIterationCount = 'unset'
        })

        loader.addEventListener('animationend', (e)=>
        {
            if(e.animationName === 'dash')
            {
                const loaderTextContainer = loader.firstChild
                loaderTextContainer.firstChild.innerHTML = 'Ready!'
                loaderTextContainer.lastChild.innerHTML = 'Welcome to my Island'

                setTimeout(()=>
                {
                    loader.style.transform = 'translateY(0vh)'
                    c.Tween.get(loader).to({transform: 'translateY(100vh)'}, 600, c.Ease.quadIn).call(function(){
                        loader.style.display = 'none'
                    })
    
                    experience.world.cameraStart()
                }, 500)

            }
        })

        //Set Points Listeners
        const pointsArr = experience.world.points.points
        pointsArr.forEach((point)=>{
            switch(point.textContent)
            {
                case 'Projects':
                    point.element.addEventListener('click', projectsDesktop)
                    break
                case 'About':
                    point.element.addEventListener('click', aboutDesktop)
                    break
                case 'Contact':
                    point.element.addEventListener('click', contactDesktop)
            }
        })

    })
}

// Projects
function projectsDesktop()
{
    if(experience.world.camera.isAnimating == true || !experience.world.points.points[2].element.classList.contains('visible')){return}

    // Reset
    desktopInterface.isInProjects = true
    desktopInterface.about.style.display = 'none'
    desktopInterface.contact.style.display = 'none'
    desktopInterface.projectContainer.style.display = 'block'
    desktopInterface.bulletsArr[desktopInterface.currProject].classList.remove('active')    
    let project = data[0]
    desktopInterface.currProject = 0
    desktopInterface.bullets.firstChild.classList.add('active')

    // Content
    setProjectTags(project)
    updateOverlayContent(project.name, project.description)
    desktopInterface.projectCard.style.backgroundImage = `url(${project.imagePath})`
    desktopInterface.projectCard.style.backgroundColor = project.bgColor

    experience.world.navigation('projects')
    webglScreen.style.cursor = 'default'
    showOverlay(2000, 900)
}

function projectsMobile()
{    
    mobileInterface.about.style.display = 'none'
    mobileInterface.projectList.style.display = 'block'
    mobileInterface.contact.style.display = 'none'
    updateOverlayContent(overlayContent.projectsTitle, overlayContent.projectsText)
    experience.world.cameraProjects()
    showOverlay(2400, 700)
}

function updateProject(isForwards)
{
    desktopInterface.bulletsArr[desktopInterface.currProject].classList.remove('active')

    // Determine new bullet point in the carousel
    isForwards ? desktopInterface.currProject++ : desktopInterface.currProject--
    if(desktopInterface.currProject >= data.length)
    {
        desktopInterface.currProject = 0
    }
    else if(desktopInterface.currProject < 0)
    {
        desktopInterface.currProject = data.length - 1
    }

    desktopInterface.bulletsArr[desktopInterface.currProject].classList.add('active')

    // Set new project data
    let project = data[desktopInterface.currProject]
    setProjectTags(project)
    updateOverlayContent(project.name, project.description)
    desktopInterface.projectCard.style.backgroundImage = `url(${project.imagePath})`
    desktopInterface.projectCard.style.backgroundColor = project.bgColor

}

// About
function aboutDesktop()
{
    if(experience.world.camera.isAnimating == true || !experience.world.points.points[0].element.classList.contains('visible')){return}

    desktopInterface.projectContainer.style.display = 'none'
    desktopInterface.contact.style.display = 'none'
    desktopInterface.about.style.display = 'block'
    updateOverlayContent(overlayContent.aboutTitle, overlayContent.aboutText)

    experience.world.navigation('about')
    webglScreen.style.cursor = 'default'
    showOverlay(2000, 900)
}

function aboutMobile()
{
    mobileInterface.about.style.display = 'block'
    mobileInterface.projectList.style.display = 'none'
    mobileInterface.contact.style.display = 'none'
    updateOverlayContent(overlayContent.aboutTitle, overlayContent.aboutText)
    experience.world.cameraAbout()
    showOverlay(2400, 700)
}

// Contact
function contactDesktop()
{
    if(experience.world.camera.isAnimating == true || !experience.world.points.points[1].element.classList.contains('visible')){return}

    desktopInterface.projectContainer.style.display = 'none'
    desktopInterface.about.style.display = 'none'
    desktopInterface.contact.style.display = 'flex'
    updateOverlayContent(overlayContent.contactTitle, overlayContent.contactText)

    experience.world.navigation('contact')
    webglScreen.style.cursor = 'default'
    showOverlay(2000, 700)
}
function contactMobile()
{
    mobileInterface.about.style.display = 'none'
    mobileInterface.projectList.style.display = 'none'
    mobileInterface.contact.style.display = 'flex'
    updateOverlayContent(overlayContent.contactTitle, overlayContent.contactText)
    experience.world.cameraContact()
    showOverlay(2400, 700)
}

// Animation
function lightMode()
{
    experience.world.lightMode()
    htmlInterface.classList.add('lightMode')
    htmlInterface.classList.remove('darkMode')
}

function darkMode()
{
    experience.world.darkMode()
    htmlInterface.classList.remove('lightMode')
    htmlInterface.classList.add('darkMode')
}

function showOverlay(waitTime, duration)
{
    if(isMobile)
    {
        mobileInterface.overlay.style.transform = 'translateY(100%)'
        c.Tween.get(mobileInterface.overlay).wait(waitTime).to({transform: 'translateY(0%)'}, duration, c.Ease.sineOut)
        .call(()=>
        {
            mobileInterface.crossIcon.style.transform = 'scale(1)'
        })
        
        hideMobileNav()
    }
    else
    {
        desktopInterface.overlay.style.transform = 'translate(-50%, 150%)'
        c.Tween.get(desktopInterface.overlay).wait(waitTime).to({transform: 'translate(-50%, -45%)'}, duration, c.Ease.sineOut)
        desktopInterface.overlayDuration = duration // Contact is faster than others
    }

    const headerText = Array.from(htmlInterface.querySelectorAll('.headerText'))
    headerText.forEach((i)=>
    {
        i.style.opacity = 0
    })
}

function closeOverlay()
{
    if(isMobile)
    {
        mobileInterface.overlay.style.transform = 'translateY(0%)'
        c.Tween.get(mobileInterface.overlay).to({transform: 'translateY(100%)'}, 700, c.Ease.sineOut)
        mobileInterface.crossIcon.style.transform = 'scale(0)'
        experience.world.cameraStart()
        showMobileNav()
    }
    else
    {
        if(experience.world.camera.isAnimating == true){return}
        
        desktopInterface.overlay.style.transform = 'translate(-50%, -45%)'
        c.Tween.get(desktopInterface.overlay).to({transform: 'translate(-50%, 150%)'}, desktopInterface.overlayDuration, c.Ease.sineOut)
        webglScreen.style.cursor = 'grab'
        experience.world.resetNavigation()

        desktopInterface.isInProjects = false
    }

    const headerText = Array.from(htmlInterface.querySelectorAll('.headerText'))
    headerText.forEach((i)=>
    {
        i.style.opacity = 1
    })
}

function hideMobileNav()
{
    mobileInterface.isAnimating = true
    mobileInterface.nav.style.opacity = 0
}

function showMobileNav()
{
    mobileInterface.isAnimating = false
    mobileInterface.nav.style.opacity = 1 
}

// Helper
function openProject(url)
{
    window.open(url, '_blank').focus();
}

function setProjectTags(projectData)
{
    desktopInterface.projectTagsArr.forEach((tag, index)=>
    {
        if(index + 1 > projectData.labels.length)
        {
            tag.style.display = 'none'
        }
        else
        {
            tag.style.display = 'block'
            tag.innerHTML = projectData.labels[index]
        }
    })
}

function updateOverlayContent(title, text)
{
    if(isMobile)
    {
        mobileInterface.title.innerHTML = title
        mobileInterface.text.innerHTML = text
    }
    else
    {
        desktopInterface.title.innerHTML = title
        desktopInterface.text.innerHTML = text
    }
}

function resize()
{
    setMobileOverlayHeight()
    window.innerWidth < 1024 ? isMobile = true : isMobile = false
    experience.world.isMobile = isMobile
}

function setMobileOverlayHeight()
{
    let viewportHeight = Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0);
    let newHeight = viewportHeight * 0.95; // Adjust this multiplier as needed
    mobileInterface.overlay.style.height = newHeight + 'px';
}

function preloadImage(url)
{
    let img = new Image()
    img.src = url
}

function isMouseOverElement(event, element) {
    const rect = element.getBoundingClientRect();
    return (
        event.clientX >= rect.left &&
        event.clientX <= rect.right &&
        event.clientY >= rect.top &&
        event.clientY <= rect.bottom
    );
}